<template>


  <v-card>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
        snackbarText
    }}</v-snackbar>
    <v-card-title class="grid-close">
      <span class="headline">Nuevo Usuario</span>
      <v-btn style="right: 10px; top: 10px" icon color="primary" fab absolute @click="$emit('cancel')">
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>


      <v-container  fluid class="pa-3">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <p>Nombre del usuario</p>
            <input class="control-input" type="text" placeholder="Ingrese el nombre" v-model="userToEdit.name" />
          </v-col>

          <v-col v-if="businesses" cols="12" sm="12" md="12">
            <p>Seleccione el comercio</p>
            <v-autocomplete :items="sortedArrayByName" v-model="userToEdit.businessID" outlined
              placeholder="Seleccione el negocio" dense class="m-0 p-0" style="border-radius: 50px" item-value="value">
              <template v-slot:selection="{ item }">
                <p class="ma-0 pa-0">
                  {{ item.text }}
                  <small class="font-weight-bold" v-if="item.cities" style="color: #FF1744">({{ item.cities }})</small>
                </p>
              </template>

              <template v-slot:item="data">
                <div class="mb-1 mt-1 fle">
                  <v-avatar v-if="data.item.logo" left>
                    <v-img :lazy-src="data.item.logo.loading" :src="data.item.logo.original"></v-img>
                  </v-avatar>
                  <span class="ml-3">
                    {{ data.item.text }}
                    <small v-if="data.item.cities" style="color: #FF1744">({{ data.item.cities }})</small>
                  </span>
                </div>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <p>Correo del usuario</p>
            <input class="control-input" type="text" placeholder="Ingrese el correo electrónico"
              v-model="userToEdit.email" />
          </v-col>

          <v-col v-if="type == 'panel'" cols="12" sm="12" md="12">
            <p>Seleccione el tipo de usuario</p>
            <v-autocomplete :items="panelRoles" v-model="userToEdit.type" outlined
              placeholder="Seleccione el tipo de usuario" dense hide-details item-text="name" class="m-0 p-0"
              style="border-radius: 50px" item-value="value"></v-autocomplete>
          </v-col>


          <v-col cols="12" sm="12" md="12">
            <p>Nueva contraseña</p>
            <input class="control-input" type="text" placeholder="Nueva contraseña" v-model="userToEdit.newPassword" />
          </v-col>

          <v-col v-if="user.type == 'experience'" cols="12" sm="12" md="12">
            <p>Seleccione el rol</p>
            <v-autocomplete :items="roles" v-model="userToEdit.role" outlined placeholder="Seleccione el rol" dense
              class="m-0 p-0" style="border-radius: 50px" item-value="value"></v-autocomplete>
          </v-col>

          <v-col v-if="userToEdit.role == 'both' && user.type == 'experience'" cols="12" sm="12" md="12">
            <p>Código de autorización</p>

            <v-text-field rounded outlined :rules="[rules.required, rules.min4]" placeholder="Código de autorización"
              hint="Código utilizado para anular boletos utilizados." v-model="userToEdit.authorizationCode" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions v-if="!loading">
      <v-container fluid>
        <v-divider></v-divider>
        <v-row class="pa-0 ma-0">
          <v-col cols="6" xs="6" sm="6" md="6">
            <v-row align="center" justify="start">
              <v-switch v-model="userToEdit.active" label="Usuario activo"></v-switch>
            </v-row>
          </v-col>

          <v-col cols="6" xs="6" sm="6" md="6">
            <v-row align="center" justify="end">
              <v-btn  class="save-btn mt-4" color="primary">Guardar</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>

</template>

<script>
import { fb, db } from "@/firebase";

// import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import { mapActions, mapState } from "vuex";
// import axios from "axios";
// import editUserSkeleton from "./../skeleton/edit-user-skeleton";

export default {
  name: "edit-user",
  props: ["user", "businesses", "newRoles", "panelRoles", "type"],
  components: {

    // editUserSkeleton,
  },
  data() {
    return {
     
      cities: [],
      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      userToEdit: [],
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
        // emailMatch: () => "The email and password you entered don't match",
        min4: (v) => (v && v.length >= 4) || "Mínimo 4 dígitos",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
      roles: [
        { text: "Administrador", value: "admin" },
        { text: "Operador", value: "operator" },
      ],
      businessesList: [],
    };
  },
  computed: {
    // getSectionToken() {
    //   return new Promise((resolve, reject) => {
    //     fb.auth()
    //       .currentUser.getIdToken(true)
    //       .then((token) => {
    //         resolve(token);
    //       });
    //   });
    // },
    sortedArrayByName: function () {
      function compare(a, b) {
        if (a.text && b.text && a.text < b.text) return -1;
        if (a.text && b.text && a.text > b.text) return 1;
        return 0;
      }
      return this.businessesList.sort(compare);
    },

    validateUser: function () {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.userToEdit.name || !this.userToEdit.email) {
        this.snackbarText = "Debe completar todos los campos.";
        this.snackbar = true;
        return false;
      } else if (!re.test(this.userToEdit.email)) {
        this.snackbarText = "Formato de correo incorrecto";
        this.snackbar = true;
        return false;
      } else if (
        this.userToEdit.newPassword &&
        this.userToEdit.newPassword.length < 8
      ) {
        this.snackbarText = "La contraseña debe tener mínimo 8 caracteres.";
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },
  },
  async mounted() {
    console.debug(this.user)
    if (this.businesses) {
      await this.$binding("cities", db.collection("cities"));

      this.businessesList = this.businesses.map((item) => {
        let cities = this.cities.filter(
          (c) => item.cities && item.cities.includes(c[".key"])
        );

        let citiesName = cities.map((e) => {
          return e.name;
        });

        return {
          text: item.shortName,
          value: item[".key"],
          cities: citiesName.join(","),
          logo: item.logo,
        };
      });
    }

    if (this.newRoles) {
      this.roles = Object.assign([], this.newRoles);
    }

    if (!this.user.email) {
      var httpUsersV2Get = fb.functions().httpsCallable("httpUsersV2Get");
      httpUsersV2Get({
        uid: this.user[".key"],
      })
        .then((result) => {
          this.user.email = result.data.email;
          this.userToEdit = Object.assign({}, this.user);
          this.userToEdit.newPassword = "";
          this.loading = false;
        })
        .catch((error) => {
          this.$emit("notFound");
        });
    } else {
      this.userToEdit = Object.assign({}, this.user);
      this.userToEdit.newPassword = "";
      this.loading = false;
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    showError(errorCode) {
      switch (errorCode) {
        case "auth/invalid-email":
          this.snackbar = true;
          this.snackbarText = "El correo electrónico es inválido.";
          break;
        case "auth/email-already-exists":
          this.snackbar = true;
          this.snackbarText = "El correo electrónico ingresado ya existe.";
          break;
        case "auth/invalid-password":
          this.snackbar = true;
          this.snackbarText =
            "Clave incorrecta, debe ser una cadena mayor a 6 caracteres";
          break;
        default:
          this.snackbar = true;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          break;
      }
    },

    async updateUser() {
      if (this.validateUser) {
        this.saving = true;
        // let token = await this.getSectionToken;
        let data = {
          name: this.userToEdit.name,
          uid: this.user[".key"],
          active: this.userToEdit.active,
          email: this.userToEdit.email,
          newPassword: this.userToEdit.newPassword,
          role: this.userToEdit.role ? this.userToEdit.role : "",
        };

        if (this.userToEdit.authorizationCode) {
          data.authorizationCode = this.userToEdit.authorizationCode;
        }

        if (this.userToEdit.type) {
          data.type = this.userToEdit.type;
        }

        if (this.userToEdit.type == "sidekick") {
          data.businessID = this.userToEdit.businessID;
        }

        var httpUsersV2Update = fb
          .functions()
          .httpsCallable("httpUsersV2Update");
        httpUsersV2Update(data)
          .then((result) => {
            this.saving = false;
            this.$emit("success", this.userToEdit);
          })
          .catch((error) => {
            this.saving = false;
            this.showError(error.message);
          });
      }
    },
  },
};
</script>

<style  scoped>
.fle {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>