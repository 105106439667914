var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))]),_c('v-overlay',{staticClass:"loading-center",attrs:{"value":_vm.saving}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":150,"width":150},on:{"animCreated":_vm.handleAnimation}})],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"11","md":"11"}},[_c('h1',[_vm._v("Usuarios cine")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){_vm.modalAddUser = true}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-plus")]),_vm._v(" Crear nuevo usuario ")],1)],1)],1)],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":15,"loading":_vm.loading,"sort-by":['name'],"sort-asc":true,"item-key":".key","search":_vm.$store.state.search,"height":"75vh","fixed-header":"","footer-props":{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                    ? 'subtitle-1 primary--text'
                    : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true},{key:`item.businesses`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.businesses && item.businesses.length ? item.businesses.length : 0)+" ")]}},{key:`item.imagen`,fn:function({}){return [_c('v-avatar',{attrs:{"color":`#${((Math.random() * 0xffffff) << 0).toString(16)}`}},[_c('img',{attrs:{"src":require('@/assets/user.svg')}})])]}},{key:`item.active`,fn:function({ item }){return [_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:`item.email`,fn:function({ item }){return [(item.email)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticStyle:{"display":"inline-block"},attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'rgba(0, 0, 0, 0.4)'
                    : 'rgba(0, 0, 0, 0.1)',"dark":"","text-color":_vm.$vuetify.theme.dark ? 'white' : 'black'},on:{"click":function($event){return _vm.copyToClipboard(item.email)}}},on),[_vm._v(_vm._s(item.email))])]}}],null,true)},[_c('i',{staticClass:"far fa-copy mr-2"}),_c('span',[_vm._v("Click para copiar")])]):_vm._e()]}},{key:`item.options`,fn:function({ item }){return [_c('div',{staticClass:"actions-table"},[_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v("Editar usuario")]),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editBusinesses(item)}}},[_vm._v("Asignar comercios")])],1)]}}],null,true)})],1)],1),(_vm.userSelected)?_c('v-dialog',{attrs:{"max-width":"900px"},model:{value:(_vm.modalEditBusinesses),callback:function ($$v) {_vm.modalEditBusinesses=$$v},expression:"modalEditBusinesses"}}):_vm._e(),(_vm.modalEditUser)?_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.modalEditUser),callback:function ($$v) {_vm.modalEditUser=$$v},expression:"modalEditUser"}},[_c('addEditUser',{key:_vm.userSelected['.key'],attrs:{"actionModal":"update","user":_vm.userSelected,"newRoles":_vm.roles},on:{"cancel":function($event){_vm.modalEditUser = false},"notFound":function($event){return _vm.userNotFound()},"success":_vm.updateSuccess}})],1):_vm._e(),(_vm.modalAddUser)?_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.modalAddUser),callback:function ($$v) {_vm.modalAddUser=$$v},expression:"modalAddUser"}},[_c('addEditUser',{attrs:{"actionModal":"add","newRoles":_vm.roles},on:{"cancel":function($event){_vm.modalAddUser = false},"notFound":function($event){return _vm.userNotFound()},"success":_vm.updateSuccess}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }