<template>
  <v-card>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card-title class="grid-close">
      <span class="headline">{{ titleModal }}</span>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        outlined
        color="primary"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form  ref="form"
    v-model="valid"
    lazy-validation>

     
      <v-row v-if="!loading" class="pt-2">
        <v-col cols="12" sm="12" md="12">
          <label>Nombre del usuario</label>
          <v-text-field
            filled
            required
            rounded
            :rules="[rules.required]"
            type="text"
            placeholder="Ingrese el nombre"
            v-model="userToEdit.name"
          />
        </v-col>

        <v-col v-if="businesses" cols="12" sm="12" md="12">
          <label>Seleccione el comercio</label>
          <v-autocomplete
            :items="sortedArrayByName"
            v-model="userToEdit.businessID"
            outlined
            placeholder="Seleccione el negocio"
            dense
            class="m-0 p-0"
            style="border-radius: 50px"
            item-value="value"
          >
            <template v-slot:selection="{ item }">
              <label class="ma-0 pa-0">
                {{ item.text }}
                <small
                  class="font-weight-bold"
                  v-if="item.cities"
                  style="color: #FF1744"
                  >({{ item.cities }})</small
                >
              </label>
            </template>

            <template v-slot:item="data">
              <div class="mb-1 mt-1 fle">
                <v-avatar v-if="data.item.logo" left>
                  <v-img
                    :lazy-src="data.item.logo.loading"
                    :src="data.item.logo.original"
                  ></v-img>
                </v-avatar>
                <span class="ml-3">
                  {{ data.item.text }}
                  <small v-if="data.item.cities" style="color: #FF1744"
                    >({{ data.item.cities }})</small
                  >
                </span>
              </div>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="12" md="12" >
          <label>Correo del usuario</label>
          <v-text-field
            filled
            :readonly="actionModal == 'update'"
            :disabled="actionModal == 'update'"
            rounded
            :rules="[rules.email]"
            required
            type="text"
            placeholder="Ingrese el correo electrónico"
            v-model="userToEdit.email"
          />
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <label>Nueva contraseña</label>
          <v-text-field
            filled
            rounded
            :rules="[rules.required]"
            type="text"
            placeholder="Nueva contraseña"
            v-model="userToEdit.newPassword"
          />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <label>Seleccione el rol de scanner</label>
          <v-autocomplete
            filled
            :items="typeRoles"
            v-model="userToEdit.role"
            :rules="[rules.required]"
            rounded
            placeholder="Seleccione el rol"
            dense
            class="m-0 p-0"
            style="border-radius: 10px"
            item-value="value"
          ></v-autocomplete>
        </v-col>

        <v-col
          v-if="userToEdit.role == 'both' && userToEdit.type == 'experience'"
          cols="12"
          sm="6"
          md="6"
        >
          <label>Código de autorización</label>

          <v-text-field
            rounded
            filled
            :rules="[rules.required, rules.min4]"
            placeholder="Código de autorización"
            hint="Código utilizado para anular boletos utilizados."
            v-model="userToEdit.authorizationCode"
          />
        </v-col>
         <v-col cols="12" sm="12" md="12"  v-if="userToEdit.role == 'both' || userToEdit.role =='food' ">
          <label>Número de caja</label>
          <v-text-field
            filled
            rounded
            :rules="[rules.required]"
            type="text"
            placeholder="Caja"
            v-model="userToEdit.cashRegister
"
          />
        </v-col>
      </v-row>
       </v-form>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <v-switch v-model="userToEdit.active" label="Usuario activo"></v-switch>
      <v-spacer></v-spacer>
      <v-btn @click="aceptarActions()" class="save-btn" color="primary"
        >Guardar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { fb, db } from "@/firebase";

// import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import { mapActions, mapState } from "vuex";
// import axios from "axios";
// import editUserSkeleton from "./../skeleton/edit-user-skeleton";

export default {
  name: "edit-user",
  props: ["userSelec", "businesses", "newRoles", "type", "actionModal"],
  components: {
    // editUserSkeleton,
  },
  data() {
    return {
       valid: true,
      cities: [],
      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: false,
       emailRules: [
        v => !!v || 'Correo Requerido',
        v => /.+@.+\..+/.test(v) || 'Correo no valido',
      ],
      userToEdit: {
        role: null,
        type: "experience",
        cashRegister:'',
        name: null,
        email: null,
      },
      rules: {
        required: (value) => !!value || "Obligatorio",
        business: (value) => value.length >= 1 || "Obligatorio",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
        // emailMatch: () => "The email and password you entered don't match",
        min4: (v) => (v && v.length >= 4) || "Mínimo 4 dígitos",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
      roles: [
        { text: "Administrador", value: "admin" },
        { text: "Operador", value: "operator" },
      ],
      typeRoles: [
        { text: "Refresqueria", value: "food" },
        { text: "Tickets", value: "tickets" },
        { text: "Admin Refresqueria / Tickets", value: "both" },
      ],
      businessesList: [],
    };
  },
  computed: {
    // getSectionToken() {
    //   return new Promise((resolve, reject) => {
    //     fb.auth()
    //       .currentUser.getIdToken(true)
    //       .then((token) => {
    //         resolve(token);
    //       });
    //   });
    // },
    getBusinesses() {
      return this.businesses.map((item) => {
        return {
          value: item[".key"],
          text: item.shortName,
        };
      });
    },
    titleModal() {
      var titletype = {
        add: "Nuevo Usuario",
        update: `Editar ${this.user ? this.user.name : "usuario"}`,
      };
      return titletype[this.actionModal];
    },
    sortedArrayByName: function () {
      function compare(a, b) {
        if (a.text && b.text && a.text < b.text) return -1;
        if (a.text && b.text && a.text > b.text) return 1;
        return 0;
      }
      return this.businessesList.sort(compare);
    },

    validateUser() {
      this.$refs.form.validate()
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        !this.userToEdit.name ||
        !this.userToEdit.email ||
        this.userToEdit.businesses.length == 0
      ) {
        this.snackbarText = "Debe completar todos los campos.";
        this.snackbar = true;
        return false;
      } else if (!re.test(this.userToEdit.email)) {
        this.snackbarText = "Formato de correo incorrecto";
        this.snackbar = true;
        return false;
      } else if (
        this.userToEdit.password &&
        this.userToEdit.password.length < 8
      ) {
        this.snackbarText = "La contraseña debe tener mínimo 8 caracteres.";
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },
  },
  async mounted() {
    if (this.businesses) {
      await this.$binding("cities", db.collection("cities"));

      this.businessesList = this.businesses.map((item) => {
        let cities = this.cities.filter(
          (c) => item.cities && item.cities.includes(c[".key"])
        );

        let citiesName = cities.map((e) => {
          return e.name;
        });

        return {
          text: item.shortName,
          value: item[".key"],
          cities: citiesName.join(","),
          logo: item.logo,
        };
      });
    }

    if (this.newRoles) {
      this.roles = Object.assign([], this.newRoles);
    }
    if (this.user && this.actionModal == "update") {
      this.userToEdit = Object.assign({}, this.user);
    }
  },
  methods: {
    aceptarActions() {
      if (this.validateUser) {
        this.saving = true;
        console.debug(this.userToEdit);
        let data;
        if (this.actionModal == "add") {
          data = {
            role: this.userToEdit.role,
            businesses: this.userToEdit.businesses,
            name: this.userToEdit.name,
            email: this.userToEdit.email,
            password: this.userToEdit.password,
            cashRegister:this.userToEdit.cashRegister || '',
            active: this.userToEdit.active,
            createdAt: new Date(),
          };

        } else {
          data = {
            userId: this.userToEdit.userId,
            role: this.userToEdit.role,
            businesses: this.userToEdit.businesses,
            password: this.userToEdit.password || '',
            name: this.userToEdit.name,
            cashRegister:this.userToEdit.cashRegister || '',
            active: this.userToEdit.active,
            
          };
        }

        var httpUsersV2Create = fb
          .functions()
          .httpsCallable( this.actionModal == "add" ?"httpExperienceAddAppUsers":"httpExperienceUpdateAppUsers");
        httpUsersV2Create(data)
          .then((result) => {
            this.saving = false;
            console.debug(result);
            this.$emit("success");
          })
          .catch((error) => {
            console.debug(error);
            this.saving = false;
            this.showError(error.message);
          });
      } else {
        this.saving = false;
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    showError(errorCode) {
      switch (errorCode) {
        case "auth/invalid-email":
          this.snackbar = true;
          this.snackbarText = "El correo electrónico es inválido.";
          break;
        case "auth/email-already-exists":
          this.snackbar = true;
          this.snackbarText = "El correo electrónico ingresado ya existe.";
          break;
        case "auth/invalid-password":
          this.snackbar = true;
          this.snackbarText =
            "Clave incorrecta, debe ser una cadena mayor a 6 caracteres";
          break;
        default:
          this.snackbar = true;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          break;
      }
    },

  },
};
</script>

<style  scoped>
.fle {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>