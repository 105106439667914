<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Usuarios cine</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            @click="modalAddUser = true"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nuevo usuario
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="users"
          :items-per-page="15"
          :loading="loading"
          :sort-by="['name']"
          :sort-asc="true"
          item-key=".key"
          :search="$store.state.search"
          height="75vh"
          fixed-header
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              >
              </v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.businesses`]="{ item }">
            {{
              item.businesses && item.businesses.length
                ? item.businesses.length
                : 0
            }}
          </template>

          <template v-slot:[`item.imagen`]="{}">
            <v-avatar
              class=""
              :color="`#${((Math.random() * 0xffffff) << 0).toString(16)}`"
            >
              <img :src="require('@/assets/user.svg')" />
            </v-avatar>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="switchControlChanged(item)"
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <v-tooltip v-if="item.email" left>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.email)"
                  >{{ item.email }}</v-chip
                >
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <div class="actions-table">
              <v-btn @click="editUser(item)" small color="primary" class="mr-3"
                >Editar usuario</v-btn
              >
              <v-btn @click="editBusinesses(item)" small color="primary"
                >Asignar comercios</v-btn
              >
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-if="userSelected"
      v-model="modalEditBusinesses"
      max-width="900px"
    >
      <!-- <edit-businesses
          @cancel="modalEditBusinesses = false"
          @success="updateSuccess"
          :key="userSelected['.key']"
          type="experience"
          :user="userSelected"
        ></edit-businesses> -->
    </v-dialog>

    <v-dialog v-if="modalEditUser" v-model="modalEditUser" max-width="800px">
      <!-- <edit-user @cancel="modalEditUser = false" @notFound="userNotFound()" @success="updateSuccess"
                :newRoles="roles" :key="userSelected['.key']" :user="userSelected"></edit-user> -->

      <addEditUser
        actionModal="update"
        @cancel="modalEditUser = false"
        @notFound="userNotFound()"
        :key="userSelected['.key']"
        :user="userSelected"
        :newRoles="roles"
        @success="updateSuccess"
      ></addEditUser>
    </v-dialog>

    <v-dialog
      v-if="modalAddUser"
      persistent
      v-model="modalAddUser"
      max-width="800px"
    >
      <!-- <add-user
                    type="experience"
                    @cancel="modalAddUser = false"
                    @success="userCreated"
                    :newRoles="roles"
                    ></add-user> -->
      <addEditUser
        actionModal="add"
        @cancel="modalAddUser = false"
        @notFound="userNotFound()"
        :newRoles="roles"
        @success="updateSuccess"
      ></addEditUser>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import * as animationData from "@/assets/ochoColor.json";
import axios from "axios";
import editBusinesses from "./edit-businessOwner-businesses.vue";
import editUser from "./formUserCine/edit-user";
import addEditUser from "./formUserCine/add-edit";
//   import addUser from "./create-user";
import { mapState } from "vuex";

export default {
  name: "users-business-owner",
  components: {
    lottie: Lottie,
    //   editBusinesses,
    editUser,
    addEditUser,
    //   addUser,
  },
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      modalEditBusinesses: false,
      userSelected: null,
      snackbarText: "",
      timer: null,
      itemToEdit: {},

      users: [],
      sudoUsers: [],
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      modalAddUser: false,
      modalEditUser: false,
      anim: null,
      animationSpeed: 1.1,
      roles: [
        { text: "Escaner refresquería", value: "food" },
        { text: "Escaner boletería", value: "tickets" },
        { text: "Administrador", value: "both" },
      ],
      headers: [
        {
          value: "imagen",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Correo",
          value: "email",
        },
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Cantidad de comercios asignados",
          value: "businesses",
          align: "center",
        },
        {
          text: "Tipo de Rol",
          value: "businesses",
          align: "center",
        },
        {
          value: "options",

          align: "end",
        },
      ],
      typeUser: ["experience"],
      typeRole: ["both", "food", "tickets"],
    };
  },
  computed: {
    ...mapState(["user"]),
    generateColor() {
      var letters = "0123456789ABCDEF".split("");
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.round(Math.random() * 10)];
      }
      return color;
    },
  },
  methods: {
    firstLetter(name) {
      return name != undefined ? name.split("")[0] : "";
    },

    userNotFound() {
      this.snackbarText = "Usuario no encontrado.";
      this.snackbar = true;
      this.modalEditUser = false;
      this.userSelected = null;
    },

    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    editUser: function (item) {
      this.userSelected = item;
      this.modalEditUser = true;
    },

    userCreated() {
      this.modalAddUser = false;
      this.snackbar = true;
      this.snackbarText = "Usuario agregado exitosamente.";
    },

    editBusinesses: function (item) {
      this.userSelected = item;
      this.modalEditBusinesses = true;
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    updateSuccess(user) {
      this.modalEditBusinesses = false;
      this.modalEditUser = false;
      this.snackbar = true;
      this.snackbarText = "Información del usuario actualizada correctamente.";
    },
    updateActiveSucc() {
      this.getAllData();
      this.snackbar = true;
      this.snackbarText = "Usuario actualizado correctamente";
    },
    switchControlChanged(item) {
      console.debug(item);
      var httpUsersV2Create = fb
        .functions()
        .httpsCallable("httpExperienceUpdateAppUsers");
      httpUsersV2Create(item)
        .then((result) => {
          this.saving = false;
          console.debug(result);
          this.updateActiveSucc();
        })
        .catch((error) => {
          console.debug(error);
          this.saving = false;
          this.showError(error.message);
        });
    },
  },

  async mounted() {
    this.$store.commit("setSearchTerm", "usuario");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    if (this.user.role == "admin") {
      await this.$binding(
        "users",
        // db.collection("appUsers").where("type", "==", "experience").where("role","in",this.typeRole)
        db
          .collection("appUsers")
          .where("type", "==", "businessOwner")
          .where("role", "==", "admin")
      );
    }

    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.actions-table {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-items: center;
}
</style>